<template>
  <div class>
    <div class="container mt-3">
      <b-row>
        <b-col md="5">
          <template v-if="$options.components['profile-show-edit-profile']">
            <profile-show-edit-profile />
          </template>
        </b-col>
        <b-col md="7" class="mt-3">
          <b-row>
            <b-col md="4">
              <div class="promotional_box text-center">
                <h3 class="mb-2">{{ promotionalData.toFixed(2) }}</h3>
                <p class="mb-0">Promotional</p>
              </div>
            </b-col>
            <b-col md="4">
              <div class="akshayptra_box text-center">
                <h3 class="mb-2">{{ akshayPatraData.toFixed(2) }}</h3>
                <p class="mb-0">Akshay Patra</p>
              </div>
            </b-col>
            <b-col md="4">
              <div class="cashback_box text-center">
                <h3 class="mb-2">{{ cashBack.toFixed(2) }}</h3>
                <p class="mb-0">Cashback</p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="10">
              <h5>Transactional History</h5>
            </b-col>
            <!-- <b-col md="2">
              <b-select style="background-color: #fff; width: 100%" id="text" type="text" label="text"
                :options="dateOption" v-model="selected" @input="getDateData"></b-select>
            </b-col> -->
          </b-row>
          <div class="mt-4 text-center promotional_akshay_tab_div">
            <b-tabs pills v-model="tabindex">
              <b-tab
                @click="getHistory('PROMOTIONAL')"
                title="Promotional"
                active
              >
                <b-card-text>
                  <b-table
                    id="my-table"
                    show-empty
                    header
                    :items="walletHistoryData"
                    :current-page="currentPage"
                    :per-page="perPage"
                    head-variant="light"
                    class="border"
                  ></b-table>
                </b-card-text>
              </b-tab>
              <b-tab @click="getHistory('AKSHAY_PATRA')" title="Akshay Patra">
                <b-card-text>
                  <b-table
                    id="my-table"
                    show-empty
                    header
                    :items="walletHistoryData"
                    :current-page="currentPage"
                    :per-page="perPage"
                    head-variant="light"
                    class="border"
                  ></b-table>
                </b-card-text>
              </b-tab>
              <b-tab @click="getHistory('CASHBACK')" title="Cashback">
                <b-card-text>
                  <b-table
                    id="my-table"
                    show-empty
                    header
                    :items="walletHistoryData"
                    :current-page="currentPage"
                    :per-page="perPage"
                    head-variant="light"
                    class="border"
                  ></b-table>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
          <div v-if="walletHistoryData.length > 10">
            <b-pagination
              class="customPagination"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              align="center"
              aria-controls="my-table"
              first-number
              last-number
            >
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "walletPromotionalAkshaypatra",
  data() {
    return {
      tabindex: 0,
      perPage: 10,
      promotionalData: 0,
      akshayPatraData: 0,
      cashBack: 0,
      currentPage: 1,
      startDate: "",
      endDate: "",
      resourceTypeId: "",
    };
  },
  computed: {
    ...mapState({
      resourceTypeData: (state) => state.profile.resourceTypeData,
      userInfo: (state) => state.auth.userInfo,
      walletHistoryData: (state) => state.profile.walletHistoryData,
    }),
    rows() {
      return this.walletHistoryData.length;
    },
  },
  watch: {
    tabindex() {
      this.currentPage = 1;
    },
  },
  methods: {
    async getHistory(data) {
      this.$store.state["profile"].walletHistoryData = [];
      const resourceType = this.resourceTypeData.filter((e) => e.code === data);
      this.resourceTypeId = resourceType[0].resourceID;
      let i = 0;
      do {
        i++;
        var z = await this.$store.dispatch("profile/getWalletHistoryListData", {
          from: this.startDate,
          to: this.endDate,
          amountType: this.resourceTypeId,
          page: i,
        });
      } while (z.data?.response?.length > 9);
    },
  },

  async mounted() {
    this.startDate = this.userInfo.createdAt.slice(0, 10);
    this.endDate = moment().add(1, "days").format("YYYY-MM-DD");
    await this.$store.dispatch("profile/fetchResourceTypeData");
    try {
      let x = await this.$store.dispatch(
        "profile/getWalletBalenceSpecific",
        "PROMOTIONAL"
      );
      this.promotionalData = x?.data?.response?.balanceData[0]?.balance || 0;

      let y = await this.$store.dispatch(
        "profile/getWalletBalenceSpecific",
        "AKSHAY_PATRA"
      );
      this.akshayPatraData = y?.data?.response?.balanceData[0]?.balance || 0;

      let z = await this.$store.dispatch(
        "profile/getWalletBalenceSpecific",
        "CASHBACK"
      );
      this.cashBack = z?.data?.response?.balanceData[0]?.balance || 0;
    } catch {
      this.akshayPatraData = 0;
      this.promotionalData = 0;
      this.cashBack = 0;
    }
    this.getHistory("PROMOTIONAL");
  },
};
</script>

<style scoped>
::v-deep .customPagination li button {
  color: #272727;
}

::v-deep .customPagination li.active .page-link,
::v-deep .customPagination li button:hover {
  color: #fd7e14;
  background-color: transparent !important;
  border: 1px solid #fd7e14;
  box-shadow: none;
}

::v-deep .page-item {
  margin-left: 0.4em;
  margin-right: 0.4em;
  border-radius: 8px !important;
}

.promotional_box {
  background-image: linear-gradient(to right, #bddffd, #3893e6);
  padding: 14px 10px;
  border-radius: 8px;
}

.akshayptra_box {
  background-image: linear-gradient(to right, #ffedaf, #ffd12d);
  padding: 14px 10px;
  border-radius: 8px;
}

.cashback_box {
  background-image: linear-gradient(to right, #b6ffaf, #42ff2d);
  padding: 14px 10px;
  border-radius: 8px;
}

.promotional_akshay_tab_div .nav {
  display: inline-flex;
}
</style>
